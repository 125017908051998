import React from 'react'
import { Field, Dropdown, tokens, Option, Text } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Control, Controller } from 'react-hook-form'
import { SubscriptionLevel, SubscriptionQuestionConnection } from '../../api/schemas/schema'
import { enumValuesToOptions } from '../../helpers/enumHelper'

type SubscriptionDropdownProps = {
   control: Control<any>
   name: any
   isDisabled: boolean
}
export const SubscriptionDropdown = ({ control, name, isDisabled }: SubscriptionDropdownProps) => {
   return (
      <Controller
         name={'selectedQuestion.subscriptions'}
         control={control}
         rules={{ required: t('RequiredField') }}
         render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Field label={t('Subscriptions')} required={true}>
               <Dropdown
                  multiselect={true}
                  value={
                     value
                        .sort((a: SubscriptionQuestionConnection, b: SubscriptionQuestionConnection) => a.subscriptionLevel - b.subscriptionLevel)
                        .map((v: SubscriptionQuestionConnection) => enumValuesToOptions(SubscriptionLevel).find((x) => x.key === v.subscriptionLevel).text)
                        .join(', ') ?? ''
                  }
                  onOptionSelect={(e, d) => {
                     onChange(d.selectedOptions.map((x) => ({ subscriptionLevel: Number(x) }) as SubscriptionQuestionConnection))
                  }}
                  selectedOptions={value.map((v: SubscriptionQuestionConnection) => v.subscriptionLevel.toString())}
                  disabled={isDisabled}
                  style={{ minWidth: 'auto' }}
               >
                  {enumValuesToOptions(SubscriptionLevel).map((x) => (
                     <Option key={x.key} value={x.key.toString()}>
                        {x.text}
                     </Option>
                  ))}
               </Dropdown>
               <Text style={{ color: tokens.colorStatusDangerForeground1 }}>{error?.message}</Text>
            </Field>
         )}
      />
   )
}
