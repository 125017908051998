/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Activity {
   id?: string | null
   modifiedBy?: string | null
   /** @format date-time */
   modifiedDate?: string
   title?: string | null
}

export interface AlternativeProcessQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   processId?: string
   process?: Process
}

export interface AnswerOption {
   /** @format uuid */
   id?: string
   text?: string | null
   /** @format int32 */
   value?: number
   infoLabel?: string | null
   /** @format int32 */
   order?: number
   modifiedBy?: string | null
   /** @format date-time */
   modifiedDate?: string
   answerType?: AnswerType
   demandConnection?: boolean
   actionRequired?: boolean
   colorHex?: string | null
}

export interface AnswerOptionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   answerOptionId?: string
   answerOption?: AnswerOption
   /** @format uuid */
   demandId?: string | null
}

/** @format int32 */
export enum AnswerType {
   YesNo = 0,
   Compliance = 1,
   OwnAnswers = 2,
   YesNoDontKnow = 3,
}

export interface Demand {
   /** @format uuid */
   id?: string
   /** @format int32 */
   publicId?: number
   designation?: string | null
   text?: string | null
   /** @format int32 */
   level?: number
   /** @format int32 */
   order?: number
   area?: DemandArea
   type?: DemandType
   subType?: DemandSubType
   origin?: Origin
   source?: string | null
   url?: string | null
   urlText?: string | null
   modifiedBy?: string | null
   /** @format date-time */
   modifiedDate?: string
   /** @format uuid */
   parentId?: string | null
   status?: DemandStatus
   questions?: Question[] | null
   version?: string | null
   children?: Demand[] | null
   parent?: Demand
}

/** @format int32 */
export enum DemandArea {
   Economy = 0,
   Sustainability = 1,
   Quality = 2,
   Staff = 3,
   Security = 4,
}

/** @format int32 */
export enum DemandComplianceModules {
   WORK_ENVIRONMENT = 0,
   GDPR = 1,
   ISO27001 = 2,
   ISO28000 = 3,
   ISO31000 = 4,
   ISO45000 = 5,
   SSF_CYBER_SECURITY = 6,
   SYSTEMATIC_FIRE_PROTECTION_WORK = 7,
   SECURITY_PROTECTION = 8,
   EXTENDED_LEVEL = 9,
}

export interface DemandSearchResultDTO {
   /** @format uuid */
   id?: string
   /** @format int32 */
   publicId?: number
   designation?: string | null
   text?: string | null
   /** @format int32 */
   level?: number
   type?: DemandType
   urlText?: string | null
}

/** @format int32 */
export enum DemandStatus {
   Definite = 0,
   Reviewing = 1,
   Ongoing = 2,
   Resting = 3,
   Archived = 4,
   SavedButNotSure = 999,
}

/** @format int32 */
export enum DemandSubType {
   EUDirective = 0,
   Law = 1,
   Paragraph = 2,
   ISOStandard = 3,
   Ordinance = 4,
   Regulation = 5,
   GeneralAdvice = 6,
   Guidance = 7,
   IndustryDemand = 8,
   InternalDemand = 9,
}

/** @format int32 */
export enum DemandType {
   PublicAuthorityDemand = 0,
   IndustryDemand = 1,
   InsuranceDemand = 2,
   OtherDemand = 3,
}

export interface FileBlob {
   /** @format int32 */
   id?: number
   name?: string | null
   /** @format uuid */
   attachmentId?: string
   /** @format byte */
   bytes?: string | null
   /** @format uuid */
   questionId?: string
}

export interface Law {
   id?: string | null
   sfsNr?: string | null
   title?: string | null
   department?: string | null
   /** @format date-time */
   issued?: string | null
   paragraphs?: Paragraph[] | null
   changed?: string | null
   status?: LawStatus
   url?: string | null
   changedBy?: string | null
   /** @format date-time */
   modified?: string | null
   /** @format date-time */
   modifiedImport?: string | null
   statusImport?: StatusImport
   /** @format uuid */
   demandId?: string | null
}

export interface LawImportStatus {
   /** @format int32 */
   numberOfNewLaws?: number
   /** @format int32 */
   numberOfUpdatedLaws?: number
   /** @format int32 */
   numberOfRevokedLaws?: number
   /** @format date-time */
   latestUpdate?: string | null
}

export interface LawPaginatedList {
   items?: Law[] | null
   /** @format int32 */
   pageIndex?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   totalItems?: number | null
   hasPreviousPage?: boolean
   hasNextPage?: boolean
}

/** @format int32 */
export enum LawStatus {
   Yes = 0,
   No = 1,
   Maybe = 2,
   Demand = 3,
   OngoingDemand = 4,
   Archived = 5,
   Unhandled = 99,
}

export interface LeadershipPerspective {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface LeadershipPerspectiveQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   leadershipPerspectiveId?: string
   leadershipPerspective?: LeadershipPerspective
}

export interface OrganisationSize {
   /** @format uuid */
   id?: string
   text?: string | null
   /** @format int32 */
   sortOrder?: number
}

export interface OrganisationSizeQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   organisationSizeId?: string
   organisationSize?: OrganisationSize
}

export interface OrganisationType {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface OrganisationTypeQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   organisationTypeId?: string
   organisationType?: OrganisationType
}

/** @format int32 */
export enum Origin {
   Sweden = 0,
}

export interface Paragraph {
   /** @format int32 */
   id?: number
   paragraphText?: string | null
   lawId?: string | null
   law?: Law
   status?: boolean
   /** @format uuid */
   demandId?: string | null
   /** @format date-time */
   modifiedImport?: string | null
   statusImport?: StatusImport
}

export interface Process {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface ProcessQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   processId?: string
   process?: Process
}

export interface Question {
   /** @format uuid */
   id?: string
   /** @format int32 */
   publicId?: number
   leadershipPerspectives?: LeadershipPerspectiveQuestionConnection[] | null
   organisationTypes?: OrganisationTypeQuestionConnection[] | null
   organisationSizes?: OrganisationSizeQuestionConnection[] | null
   subjects?: SubjectQuestionConnection[] | null
   process?: ProcessQuestionConnection
   alternativeProcesses?: AlternativeProcessQuestionConnection[] | null
   snIs?: SNIQuestionConnection[] | null
   demandComplianceModules?: string | null
   demandComplianceModulesEnum?: DemandComplianceModules[] | null
   text?: string | null
   backgroundInformation?: string | null
   proposedAction?: string | null
   modifiedBy?: string | null
   /** @format date-time */
   modifiedDate?: string
   /** @format uuid */
   parentId?: string
   status?: QuestionStatus
   /** @format int32 */
   order?: number
   answerOptions?: AnswerOptionConnection[] | null
   answerType?: AnswerType
   attachment?: FileBlob
   parent?: Demand
   /** @format int32 */
   valuation?: number | null
   subscriptions?: SubscriptionQuestionConnection[] | null
}

export interface QuestionDTO {
   /** @format uuid */
   id?: string
   /** @format int32 */
   publicId?: number
   leadershipPerspectives?: LeadershipPerspectiveQuestionConnection[] | null
   organisationTypes?: OrganisationTypeQuestionConnection[] | null
   organisationSizes?: OrganisationSizeQuestionConnection[] | null
   subjects?: SubjectQuestionConnection[] | null
   process?: ProcessQuestionConnection
   alternativeProcesses?: AlternativeProcessQuestionConnection[] | null
   snIs?: SNIQuestionConnection[] | null
   demandComplianceModules?: string | null
   demandComplianceModulesEnum?: DemandComplianceModules[] | null
   text?: string | null
   backgroundInformation?: string | null
   proposedAction?: string | null
   modifiedBy?: string | null
   /** @format date-time */
   modifiedDate?: string
   /** @format uuid */
   parentId?: string
   status?: QuestionStatus
   /** @format int32 */
   order?: number
   answerOptions?: AnswerOptionConnection[] | null
   answerType?: AnswerType
   attachment?: FileBlob
   parent?: Demand
   /** @format int32 */
   valuation?: number | null
   subscriptions?: SubscriptionQuestionConnection[] | null
   designation?: string | null
}

export interface QuestionDTOPaginatedList {
   items?: QuestionDTO[] | null
   /** @format int32 */
   pageIndex?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   totalItems?: number | null
   hasPreviousPage?: boolean
   hasNextPage?: boolean
}

/** @format int32 */
export enum QuestionStatus {
   Definite = 0,
   Ongoing = 1,
   Reviewing = 2,
   Archived = 3,
   Resting = 4,
}

export interface SNI {
   /** @format uuid */
   id?: string
   department?: string | null
   category?: string | null
   /** @format int32 */
   code?: number
   comment?: string | null
   version?: SNIVersion
}

export interface SNIQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   sniId?: string
   sni?: SNI
}

/** @format int32 */
export enum SNIVersion {
   SNI2007 = 2007,
   SNI2025 = 2025,
}

/** @format int32 */
export enum StatusImport {
   New = 100,
   Updated = 101,
   Revoked = 102,
   Empty = 103,
}

export interface Subject {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface SubjectQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   /** @format uuid */
   subjectId?: string
   subject?: Subject
}

/** @format int32 */
export enum SubscriptionLevel {
   FREE = 0,
   MINI = 1,
   BASE = 2,
   BIG = 3,
   PLUS = 4,
   ADD_ON = 99,
}

export interface SubscriptionQuestionConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   questionId?: string
   subscriptionLevel?: SubscriptionLevel
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
   /** set parameter to `true` for call `securityWorker` for this request */
   secure?: boolean
   /** request path */
   path: string
   /** content type of request body */
   type?: ContentType
   /** query params */
   query?: QueryParamsType
   /** format of response (i.e. response.json() -> format: "json") */
   format?: ResponseFormat
   /** request body */
   body?: unknown
   /** base url */
   baseUrl?: string
   /** request cancellation token */
   cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
   baseUrl?: string
   baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
   securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
   customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
   data: D
   error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
   Json = 'application/json',
   FormData = 'multipart/form-data',
   UrlEncoded = 'application/x-www-form-urlencoded',
   Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
   public baseUrl: string = ''
   private securityData: SecurityDataType | null = null
   private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
   private abortControllers = new Map<CancelToken, AbortController>()
   private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

   private baseApiParams: RequestParams = {
      credentials: 'same-origin',
      headers: {},
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
   }

   constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
      Object.assign(this, apiConfig)
   }

   public setSecurityData = (data: SecurityDataType | null) => {
      this.securityData = data
   }

   protected encodeQueryParam(key: string, value: any) {
      const encodedKey = encodeURIComponent(key)
      return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
   }

   protected addQueryParam(query: QueryParamsType, key: string) {
      return this.encodeQueryParam(key, query[key])
   }

   protected addArrayQueryParam(query: QueryParamsType, key: string) {
      const value = query[key]
      return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
   }

   protected toQueryString(rawQuery?: QueryParamsType): string {
      const query = rawQuery || {}
      const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
      return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
   }

   protected addQueryParams(rawQuery?: QueryParamsType): string {
      const queryString = this.toQueryString(rawQuery)
      return queryString ? `?${queryString}` : ''
   }

   private contentFormatters: Record<ContentType, (input: any) => any> = {
      [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
      [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
      [ContentType.FormData]: (input: any) =>
         Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            formData.append(
               key,
               property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`
            )
            return formData
         }, new FormData()),
      [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
   }

   protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
      return {
         ...this.baseApiParams,
         ...params1,
         ...(params2 || {}),
         headers: {
            ...(this.baseApiParams.headers || {}),
            ...(params1.headers || {}),
            ...((params2 && params2.headers) || {}),
         },
      }
   }

   protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
      if (this.abortControllers.has(cancelToken)) {
         const abortController = this.abortControllers.get(cancelToken)
         if (abortController) {
            return abortController.signal
         }
         return void 0
      }

      const abortController = new AbortController()
      this.abortControllers.set(cancelToken, abortController)
      return abortController.signal
   }

   public abortRequest = (cancelToken: CancelToken) => {
      const abortController = this.abortControllers.get(cancelToken)

      if (abortController) {
         abortController.abort()
         this.abortControllers.delete(cancelToken)
      }
   }

   public request = async <T = any, E = any>({
      body,
      secure,
      path,
      type,
      query,
      format,
      baseUrl,
      cancelToken,
      ...params
   }: FullRequestParams): Promise<HttpResponse<T, E>> => {
      const secureParams =
         ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
      const requestParams = this.mergeRequestParams(params, secureParams)
      const queryString = query && this.toQueryString(query)
      const payloadFormatter = this.contentFormatters[type || ContentType.Json]
      const responseFormat = format || requestParams.format

      return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
         ...requestParams,
         headers: {
            ...(requestParams.headers || {}),
            ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
         },
         signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
         body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }).then(async (response) => {
         const r = response as HttpResponse<T, E>
         r.data = null as unknown as T
         r.error = null as unknown as E

         const data = !responseFormat
            ? r
            : await response[responseFormat]()
                 .then((data) => {
                    if (r.ok) {
                       r.data = data
                    } else {
                       r.error = data
                    }
                    return r
                 })
                 .catch((e) => {
                    r.error = e
                    return r
                 })

         if (cancelToken) {
            this.abortControllers.delete(cancelToken)
         }

         if (!response.ok) throw data
         return data
      })
   }
}

/**
 * @title api
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
   api = {
      /**
       * No description
       *
       * @tags Activity
       * @name ActivityList
       * @request GET:/api/Activity
       */
      activityList: (
         query?: {
            /** @format int32 */
            take?: number
         },
         params: RequestParams = {}
      ) =>
         this.request<Activity[], any>({
            path: `/api/Activity`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags AnswerOption
       * @name AnswerOptionCreate
       * @request POST:/api/AnswerOption
       */
      answerOptionCreate: (data: AnswerOption, params: RequestParams = {}) =>
         this.request<AnswerOption, any>({
            path: `/api/AnswerOption`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags AnswerOption
       * @name AnswerOptionList
       * @request GET:/api/AnswerOption
       */
      answerOptionList: (params: RequestParams = {}) =>
         this.request<AnswerOption[], any>({
            path: `/api/AnswerOption`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags AnswerOption
       * @name AnswerOptionUpdate
       * @request PUT:/api/AnswerOption
       */
      answerOptionUpdate: (data: AnswerOption, params: RequestParams = {}) =>
         this.request<AnswerOption, any>({
            path: `/api/AnswerOption`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags AnswerOption
       * @name AnswerOptionDetail
       * @request GET:/api/AnswerOption/{id}
       */
      answerOptionDetail: (id: string, params: RequestParams = {}) =>
         this.request<AnswerOption, any>({
            path: `/api/AnswerOption/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags AnswerOption
       * @name AnswerOptionDelete
       * @request DELETE:/api/AnswerOption/{id}
       */
      answerOptionDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/AnswerOption/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Client
       * @name ClientOrganisationCountList
       * @request GET:/api/Client/organisation/count
       */
      clientOrganisationCountList: (params: RequestParams = {}) =>
         this.request<number, any>({
            path: `/api/Client/organisation/count`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandCreate
       * @request POST:/api/Demand
       */
      demandCreate: (data: Demand, params: RequestParams = {}) =>
         this.request<Demand, any>({
            path: `/api/Demand`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandList
       * @request GET:/api/Demand
       */
      demandList: (params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandUpdate
       * @request PUT:/api/Demand
       */
      demandUpdate: (data: Demand, params: RequestParams = {}) =>
         this.request<Demand, any>({
            path: `/api/Demand`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandBatchCreate
       * @request POST:/api/Demand/batch
       */
      demandBatchCreate: (data: Demand[], params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand/batch`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandBatchUpdate
       * @request PUT:/api/Demand/batch
       */
      demandBatchUpdate: (data: Demand[], params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand/batch`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandParentidDetail
       * @request GET:/api/Demand/parentid/{id}
       */
      demandParentidDetail: (
         id: string,
         query?: {
            getGrandChildren?: boolean
         },
         params: RequestParams = {}
      ) =>
         this.request<Demand[], any>({
            path: `/api/Demand/parentid/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandDetail
       * @request GET:/api/Demand/{id}
       */
      demandDetail: (id: string, params: RequestParams = {}) =>
         this.request<Demand, any>({
            path: `/api/Demand/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandDelete
       * @request DELETE:/api/Demand/{id}
       */
      demandDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Demand/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandArchiveUpdate
       * @request PUT:/api/Demand/archive
       */
      demandArchiveUpdate: (data: Demand, params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand/archive`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandArchiveList
       * @request GET:/api/Demand/archive
       */
      demandArchiveList: (params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand/archive`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandResetlawDelete
       * @request DELETE:/api/Demand/resetlaw{id}
       */
      demandResetlawDelete: (id: string, data: string, params: RequestParams = {}) =>
         this.request<Demand[], any>({
            path: `/api/Demand/resetlaw${id}`,
            method: 'DELETE',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandSearchList
       * @request GET:/api/Demand/search
       */
      demandSearchList: (
         query?: {
            searchText?: string
            /** @format int32 */
            demandLevel?: number
         },
         params: RequestParams = {}
      ) =>
         this.request<DemandSearchResultDTO[], any>({
            path: `/api/Demand/search`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Demand
       * @name DemandOrderBatchUpdate
       * @request PUT:/api/Demand/order/batch
       */
      demandOrderBatchUpdate: (data: Demand[], params: RequestParams = {}) =>
         this.request<boolean, any>({
            path: `/api/Demand/order/batch`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Fileblob
       * @name FileblobDelete
       * @request DELETE:/api/Fileblob/{id}
       */
      fileblobDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Fileblob/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Fileblob
       * @name FileblobDetail
       * @request GET:/api/Fileblob/{id}
       */
      fileblobDetail: (id: string, params: RequestParams = {}) =>
         this.request<FileBlob[], any>({
            path: `/api/Fileblob/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Law
       * @name GetApi
       * @request GET:/api/Law
       */
      getApi: (
         query?: {
            statuses?: LawStatus[]
            statusImports?: StatusImport[]
            columnSort?: string
            sortOrder?: string
            /** @format int32 */
            pageSize?: number
            /**
             * @format int32
             * @default 1
             */
            page?: number
         },
         params: RequestParams = {}
      ) =>
         this.request<LawPaginatedList, any>({
            path: `/api/Law`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Law
       * @name PutApi
       * @request PUT:/api/Law
       */
      putApi: (data: Law, params: RequestParams = {}) =>
         this.request<Law, any>({
            path: `/api/Law`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Law
       * @name GetApi2
       * @request GET:/api/Law/{id}
       * @originalName getApi
       * @duplicate
       */
      getApi2: (id: string, params: RequestParams = {}) =>
         this.request<Law, any>({
            path: `/api/Law/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Law
       * @name LawImportStatusList
       * @request GET:/api/Law/importStatus
       */
      lawImportStatusList: (params: RequestParams = {}) =>
         this.request<LawImportStatus, any>({
            path: `/api/Law/importStatus`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Law
       * @name LawDemandIdDetail
       * @request GET:/api/Law/demandId/{id}
       */
      lawDemandIdDetail: (id: string, params: RequestParams = {}) =>
         this.request<Law, any>({
            path: `/api/Law/demandId/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags LeadershipPerspective
       * @name LeadershipPerspectiveCreate
       * @request POST:/api/LeadershipPerspective
       */
      leadershipPerspectiveCreate: (data: LeadershipPerspective, params: RequestParams = {}) =>
         this.request<LeadershipPerspective, any>({
            path: `/api/LeadershipPerspective`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags LeadershipPerspective
       * @name LeadershipPerspectiveList
       * @request GET:/api/LeadershipPerspective
       */
      leadershipPerspectiveList: (params: RequestParams = {}) =>
         this.request<LeadershipPerspective[], any>({
            path: `/api/LeadershipPerspective`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags LeadershipPerspective
       * @name LeadershipPerspectiveUpdate
       * @request PUT:/api/LeadershipPerspective
       */
      leadershipPerspectiveUpdate: (data: LeadershipPerspective, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/LeadershipPerspective`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags LeadershipPerspective
       * @name LeadershipPerspectiveDelete
       * @request DELETE:/api/LeadershipPerspective/{id}
       */
      leadershipPerspectiveDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/LeadershipPerspective/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationSize
       * @name OrganisationSizeCreate
       * @request POST:/api/OrganisationSize
       */
      organisationSizeCreate: (data: OrganisationSize, params: RequestParams = {}) =>
         this.request<OrganisationSize, any>({
            path: `/api/OrganisationSize`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationSize
       * @name OrganisationSizeList
       * @request GET:/api/OrganisationSize
       */
      organisationSizeList: (params: RequestParams = {}) =>
         this.request<OrganisationSize[], any>({
            path: `/api/OrganisationSize`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationSize
       * @name OrganisationSizeUpdate
       * @request PUT:/api/OrganisationSize
       */
      organisationSizeUpdate: (data: OrganisationSize, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/OrganisationSize`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationSize
       * @name OrganisationSizeDelete
       * @request DELETE:/api/OrganisationSize/{id}
       */
      organisationSizeDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/OrganisationSize/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationType
       * @name OrganisationTypeCreate
       * @request POST:/api/OrganisationType
       */
      organisationTypeCreate: (data: OrganisationType, params: RequestParams = {}) =>
         this.request<OrganisationType, any>({
            path: `/api/OrganisationType`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationType
       * @name OrganisationTypeList
       * @request GET:/api/OrganisationType
       */
      organisationTypeList: (params: RequestParams = {}) =>
         this.request<OrganisationType[], any>({
            path: `/api/OrganisationType`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationType
       * @name OrganisationTypeUpdate
       * @request PUT:/api/OrganisationType
       */
      organisationTypeUpdate: (data: OrganisationType, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/OrganisationType`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationType
       * @name OrganisationTypeDelete
       * @request DELETE:/api/OrganisationType/{id}
       */
      organisationTypeDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/OrganisationType/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Paragraph
       * @name ParagraphDetail
       * @request GET:/api/Paragraph/{lawId}
       */
      paragraphDetail: (lawId: string, params: RequestParams = {}) =>
         this.request<Paragraph[], any>({
            path: `/api/Paragraph/${lawId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Paragraph
       * @name ParagraphUpdate
       * @request PUT:/api/Paragraph
       */
      paragraphUpdate: (data: Paragraph, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Paragraph`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags Process
       * @name ProcessCreate
       * @request POST:/api/Process
       */
      processCreate: (data: Process, params: RequestParams = {}) =>
         this.request<Process, any>({
            path: `/api/Process`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Process
       * @name ProcessList
       * @request GET:/api/Process
       */
      processList: (params: RequestParams = {}) =>
         this.request<Process[], any>({
            path: `/api/Process`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Process
       * @name ProcessUpdate
       * @request PUT:/api/Process
       */
      processUpdate: (data: Process, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Process`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags Process
       * @name ProcessDelete
       * @request DELETE:/api/Process/{id}
       */
      processDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Process/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionCreate
       * @request POST:/api/Question
       */
      questionCreate: (data: Question, params: RequestParams = {}) =>
         this.request<QuestionDTO, any>({
            path: `/api/Question`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionList
       * @request GET:/api/Question
       */
      questionList: (
         query?: {
            onlyActive?: boolean
            /** @format int32 */
            page?: number
         },
         params: RequestParams = {}
      ) =>
         this.request<QuestionDTO[], any>({
            path: `/api/Question`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionUpdate
       * @request PUT:/api/Question
       */
      questionUpdate: (data: Question, params: RequestParams = {}) =>
         this.request<QuestionDTO, any>({
            path: `/api/Question`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionBatchCreate
       * @request POST:/api/Question/batch
       */
      questionBatchCreate: (data: Question[], params: RequestParams = {}) =>
         this.request<QuestionDTO[], any>({
            path: `/api/Question/batch`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionPaginatedList
       * @request GET:/api/Question/paginated
       */
      questionPaginatedList: (
         query?: {
            /** @format int32 */
            pageIndex?: number
            /** @format int32 */
            pageSize?: number
            getArchived?: boolean
         },
         params: RequestParams = {}
      ) =>
         this.request<QuestionDTOPaginatedList, any>({
            path: `/api/Question/paginated`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionParentDetail
       * @request GET:/api/Question/parent/{demandId}
       */
      questionParentDetail: (demandId: string, params: RequestParams = {}) =>
         this.request<QuestionDTO[], any>({
            path: `/api/Question/parent/${demandId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionDetail
       * @request GET:/api/Question/{id}
       */
      questionDetail: (id: string, params: RequestParams = {}) =>
         this.request<QuestionDTO, any>({
            path: `/api/Question/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionDelete
       * @request DELETE:/api/Question/{id}
       */
      questionDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Question/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionArchiveUpdate
       * @request PUT:/api/Question/archive
       */
      questionArchiveUpdate: (data: Question[], params: RequestParams = {}) =>
         this.request<QuestionDTO[], any>({
            path: `/api/Question/archive`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Question
       * @name QuestionCountList
       * @request GET:/api/Question/count
       */
      questionCountList: (params: RequestParams = {}) =>
         this.request<number, any>({
            path: `/api/Question/count`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SNI
       * @name PostApi
       * @request POST:/api/SNI
       */
      postApi: (data: SNI, params: RequestParams = {}) =>
         this.request<SNI, any>({
            path: `/api/SNI`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SNI
       * @name GetApi3
       * @request GET:/api/SNI
       * @originalName getApi
       * @duplicate
       */
      getApi3: (
         query?: {
            group?: string
         },
         params: RequestParams = {}
      ) =>
         this.request<SNI[], any>({
            path: `/api/SNI`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SNI
       * @name PutApi2
       * @request PUT:/api/SNI
       * @originalName putApi
       * @duplicate
       */
      putApi2: (data: SNI, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/SNI`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags SNI
       * @name DeleteApi
       * @request DELETE:/api/SNI/{id}
       */
      deleteApi: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/SNI/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Subject
       * @name SubjectCreate
       * @request POST:/api/Subject
       */
      subjectCreate: (data: Subject, params: RequestParams = {}) =>
         this.request<Subject, any>({
            path: `/api/Subject`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Subject
       * @name SubjectList
       * @request GET:/api/Subject
       */
      subjectList: (params: RequestParams = {}) =>
         this.request<Subject[], any>({
            path: `/api/Subject`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Subject
       * @name SubjectUpdate
       * @request PUT:/api/Subject
       */
      subjectUpdate: (data: Subject, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Subject`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags Subject
       * @name SubjectDelete
       * @request DELETE:/api/Subject/{id}
       */
      subjectDelete: (id: string, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/Subject/${id}`,
            method: 'DELETE',
            ...params,
         }),
   }
}
