import { ILanguage } from '../../interfaces/ILanguage'

export const sv: ILanguage = {
   AppHeader: '365 Back office',
   SignIn: 'Logga in',
   SignOut: 'Logga ut',
   WelcomeTo: 'Välkommen till',

   Title: 'Titel',
   Status: 'Status',
   RelevanceStatus: 'Hanteringsstatus',
   ID: 'ID',
   SfsNo: 'SFS Nr',
   Department: 'Departement/Myndighet',
   IssuedAt: 'Utfärdad',
   ModifiedAt: 'Ändrad',
   ModificationRegister: 'Ändringsregister',
   Source: 'Källa',
   Content: 'Innehåll',

   ShowLawsWithStatusNo: 'Visa lagar med status "Nej"',
   Number: 'Nummer',
   Subject: 'Ämne',
   LeadershipPerspective: 'Ledningsperspektiv',
   NumberOfLaws: 'Antal lagar',
   Modified: 'Senast ändrad',
   ModifiedBy: 'Senast ändrad av',
   NewDemand: 'Nytt krav',
   ChoosenDemands: 'Utvalda krav',
   ChoosenDemandsInfo: 'Detta är de krav som är valda och skapta. Det är dessa som kommer speglas i 365 Robust. Under fliken "Lagar" kan du ta in fler lagar',
   WebLink: 'Webblänk',
   Description: 'Beskrivning',
   ChooseParagraphsInfo: 'Välj de paragrafer som ska bli krav',
   ClearDemand: 'Rensa pågående kravhantering',
   ClearDemandText: 'Är du säker på att du vill rensa den pågående kravhanteringen? Detta raderar data och återställer statusen. Det går ej att ångra',
   QuestionWillBeDeletedOnContinue:
      'Denna fråga kommer att raderas om du klickar på fortsätt. Vänligen spara frågan innan du byter fråga ifall att du vill spara den.',

   //User Message Bar
   LawWithStatusNoCantBeHandled: 'Lagen har hanteringsstatus "Nej"',
   ChangeStatusToHandleLaw: 'Ändra hanteringsstatus för att kunna bearbeta lagen.',
   LawHasBeenSentToDemandList: 'Lagen har skickats till krav- och frågelistan',
   LawCantBeEdited: 'Ingen hantering ska kunna ske',
   LawHasBeenUpdatedSinceLastUpdate: 'Lagen har UPPDATERATS sedan senaste hantering',
   ChangeStatusToUpdateImportStatus: 'Ändra hanteringsstatus eller välj "Behåll hanteringsstatus" för att uppdatera importstatusen.',
   LawIsUpdated: 'Lagen är uppdaterad',
   PleaseReviewDemands: 'Vänligen granska kraven.',
   NoResultByFilter: 'Inga resultat hittades för de valda filtren.',
   AdjustFilterToFindWhatYoureLookingFor: 'Prova att justera filtren för att hitta det du söker',

   // Nav
   Dashboard: 'Dashboard',
   Home: 'Hem',
   CustomerRegister: 'Kundregister',
   DemandQuestionList: 'Krav- och frågelista',
   LawsImport: 'Import av lagar',
   KnowledgeBankFormCustomer: 'Kunskapsbank för kund',
   SystemSettings: 'Systeminställningar',

   // Status
   Yes: 'Ja',
   No: 'Nej',
   Maybe: 'Kanske',
   Demand: 'Krav',
   OngoingDemand: 'Pågående krav',
   Updated: 'Uppdaterad',
   Revoked: 'Upphävd',
   New: 'Ny',
   All: 'Alla',
   Empty: '(Tom)',
   Unhandled: 'Ej hanterad',
   Definite: 'Fastställd',
   Ongoing: 'Pågående',
   Reviewing: 'Revideras',
   Resting: 'Vilande',
   Archived: 'Arkiverad',
   SavedButNotSure: '',
   Archive: 'Arkivera',

   // Laws
   ImportedLaws: 'Importerade lagar',

   // Demand
   SubType: 'Underkravtyp',
   EUDirective: 'EU förordning',
   Law: 'Lag',
   Paragraph: 'Paragraf',
   ISOStandard: 'ISO standard',
   Ordinance: 'Förordning', // Förordning
   Regulation: 'Föreskrift', // Föreskrift
   GeneralAdvice: 'Allmänt råd',
   Guidance: 'Vägledning',
   IndustryDemand: 'Branschkrav',
   InternalDemand: 'Internt krav',
   DemandType: 'Kravtyp',
   Origin: 'Ursprung',
   Sweden: 'Sverige',
   DemandLevel: 'Kravnivå',
   PublicAuthorityDemand: 'Myndighetskrav',
   InsuranceDemand: 'Försäkringskrav',
   OtherDemand: 'Annat krav',
   DemandArea: 'Kravområde',
   Economy: 'Ekonomi',
   Sustainability: 'Hållbarhet',
   Quality: 'Kvalitet',
   Environment: 'Miljö',
   Staff: 'Personal',
   Security: 'Säkerhet',
   StatusImport: 'Importstatus',
   Question: 'Fråga',
   DemandText: 'Kravtext',
   AnswerType: 'Svarsform',
   DemandSource: 'Kravkälla',
   AllDemands: 'Alla krav',
   UrlText: 'Webbtext',
   NewSubDemand: 'Nytt underkrav',
   ParentDemand: 'Överkrav',
   SubDemands: 'Underkrav',
   DemandArchiveWarningText: 'Är du säker på att du vill arkivera detta krav med underliggande information?',
   DemandDeleteWarningText: 'Är du säker på att du vill radera detta krav?',
   ArchivedDemands: 'Arkiverade krav',
   Activate: 'Aktivera',
   DemandActivateWarningText: 'Är du säker på att du vill aktivera detta krav och underliggande information igen?',
   ActiveDemands: 'Aktiva krav',
   ActiveQuestions: 'Aktiva frågor',
   ImportedDemands: 'Importerade krav',
   Version: 'Version',
   NoArchivedDemands: 'Det finns inga arkiverade krav',
   ShowMore: 'Visa fler',
   ShowLess: 'Visa färre',
   of: 'av',
   EnterURL: 'Ange URL',
   LawDemandIsBasedOnHasChanged: 'Lagen detta krav baseras på har ändrats',
   AcknowledgeChange: 'Tagit del av ändring',
   ChooseParentDemand: 'Välj överkrav',
   NewParentDemand: 'Nytt överkrav',
   SetParentDemand: 'Sätt överkrav',

   // Validation
   Warning: 'Varning',
   SendToDemandList: 'Skicka till kravlista',
   Save: 'Spara',
   Cancel: 'Avbryt',
   Clear: 'Rensa',
   Undo: 'Ångra',
   Delete: 'Radera',
   Confirm: 'Bekräfta',
   SavedSuccess: 'Sparning lyckad',
   SavedError: 'Sparningen misslyckades',
   SavedDemands: 'Kraven är sparade',
   DemandsSent: 'Kraven är skickade till kravlistan',
   DemandMoveError: 'Misslyckades med att flytta krav',
   DemandFetchError: 'Misslyckades med att hämta krav',
   QuestionSaved: 'Frågan är sparad',
   DemandSuccessfullyCleared: 'Den pågående kravhanteringen är rensad och nollställd',
   DemandClearingFailed: 'Något gick fel när den pågående kravhanteringen skulle rensas och nollställas',
   RequiredField: 'Vänligen, fyll i detta fält',
   UndoDemandChanges: 'Vill du ångra dina ändringar för detta krav?',
   SaveBeforeProceed: 'Spara dina ändringar innan du fortsätter',
   FormHasUnsavedChanges: 'Formuläret har osparade ändringar. Vänligen, spara ändringarna innan du fortsätter',
   LatestChangesWillBeUndone: 'Ändringarna återställs.',
   AreYouSureLatestChangesWillBeUndone:
      'Är du säker på att du vill fortsätta? Dina senaste ändringar kommer att återställas. Om du vill spara dina ändringar, vänligen klicka på avbryt och spara formuläret innan du fortsätter',
   UndoQuestionChanges: 'Vill du ångra dina ändringar i denna fråga?',
   UndoChanges: 'Ångra ändringar',
   Continue: 'Fortsätt',
   FetchFailed: 'Hämtning misslyckades',
   ItemDeleted: 'Objektet raderades framgångsrikt',
   DeleteError: 'Objektet kunde inte raderas',
   DeleteAttachment: 'Radera bilaga',
   CantChangeParentByDND: 'Du kan inte tilldela kravet en ny förälder genom drag and drop',
   CantDNDOnArchived: 'Du kan inte ändra ordningen på arkiverade krav',
   CantChangeParentOrLevelByDND: 'Du kan inte tilldela kravet en ny förälder eller nivå genom drag and drop',
   ActionNotSupported: 'Återgärden stöds inte',
   CouldNotFetchQuestions: 'Något gick fel vid hämtningen av frågor',
   CouldNotFetchLaw: 'Något gick fel i hämtningen av lagen',
   LawDoesNotContainParagraphs: 'Lagen innehåller inga paragrafer.',
   ParagraphsCouldNotBeFetched: 'Inga paragrafer kunde hämtas in.',
   QuestionDeleted: 'Frågan är raderad',
   CouldNotAcknowledgeChange: 'Kunde inte bekräfta att du tagit del av ändring i lag ',
   CouldNotFetchDemand: 'Något gick fel i hämtningen av kravet',
   ConfirmAcknowledgeChange: 'Bekräfta att du tagit del av ändring',
   ConfirmAcknowledgeChangeText: 'Importstatusen nollställs och meddelandet om ändringar i lagen kommer att försvinna. Detta gäller lagen med sfs nummer: ',
   CouldntExpandRow: 'Kunde inte expandera rad',
   LawStatusError: 'Det gick inte att uppdatera lagens status',
   LawUpdated: 'Lagen uppdaterades!',
   ReviewPerformed: 'Granskning utförd!',
   SomethingWentWrong: 'Något gick fel',
   CouldntFetchDashboardData: 'Kunde inte hämta dashboard data',
   DNDSuccess: 'Kraven har bytt ordning',
   DNDFail: 'Det gick inte att byta ordning på kraven',

   // Question
   QuestionsForDemand: 'Frågor till krav',
   NewQuestion: 'Ny fråga',
   BackgroundInformation: 'Bakgrundsfakta',
   ProposedAction: '365Robust tipsar',
   OrganisationType: 'Organisationsform',
   OrganisationSize: 'Organisationsstorlek',
   Process: 'Process',
   AlternativeProcesses: 'Alternativa processer',
   SNI: 'SNI',
   SNI2007: 'SNI 2007',
   SNI2025: 'SNI 2025',
   AdditionalModules: 'Tilläggsmoduler',
   Subscription: 'Abonnemang',
   Attachment: 'Bilaga',
   AddFile: 'Lägg till fil',
   NoQuestionsCreated: 'Det finns inga frågor kopplat till detta krav',
   NoQuestionChosen: 'Välj en fråga för att se innehållet',
   DeleteAttachmentText: 'Är du säker på att du vill radera bilagan?',
   QuestionWithCombinationAlreadyExists: 'En fråga med denna kombination av värden finns redan',
   ShowQuestion: 'Se fråga',
   LoadingQuestions: 'Hämtar frågor',
   Subscriptions: 'Abonnemang',

   //AnswerOptions
   YesNo: 'Ja/Nej',
   YesNoDontKnow: 'Ja/Nej/Vet inte',
   Compliance: 'Efterlevnad',
   OwnAnswers: 'Egna svar',
   AddAnswerOption: 'Lägg till svarsalternativ',
   AnswerOption: 'Svarsalternativ',
   Info: 'Info',
   Value: 'Värde',
   DemandConnection: 'Koppla krav',
   UndoAnswerOptionChanges: 'Vill du ångra dina ändringar för detta svarsalternativ?',
   Catalog: 'Katalog',
   AnswerOptionList: 'Lista för svarsalternativ',
   Order: 'Ordning',
   CreateNewAnswerOption: 'Nytt svarsalternativ',
   ConnectDemandToAnswer: 'Ska man kunna koppla krav till detta svarsalternativ?',
   SelectAnAnswerOption: 'Välj ett svarsalternativ',
   SelectAnOption: 'Gör ett val',
   SelectOneOrMoreOptions: 'Gör ett eller flera val',
   DeleteDemandConnection: 'Ta bort kravkoppling',
   ExportedSuccess: 'Export lyckades',
   ExportFail: 'Exporting failed',
   ExportingDemandsAndQuestions: 'Exporterar krav och frågor',
   PreparingExcel: 'Förbereder excel',
   FetchingDemands: 'Hämtar krav',
   FetchingQuestions: 'Hämtar frågor',
   GeneratingExcel: 'Genererar excel',
   ChangedTo: 'Ändrad till',

   //Labels / Placeholders
   Search: 'Sök',
   Designation: 'Benämning',
   ExpandAll: 'Expandera vy',
   MiniMizeAll: 'Minimera',
   SortOnColumn: 'Sortera på kolumn',
   FilterOnProcessStatus: 'Filtrera på hanteringsstatus',
   FilterOnImportStatus: 'Filtrera på importstatus',
   LawsWithFilter: 'lagar med filtrering',
   LawsWithoutFilter: 'lagar utan filtrering',
   Valuation: 'Värdering',

   //Modal header / Modal content
   MoveDemand: 'Flytta krav',
   MoveTargetTo: 'Flytta till...',
   DemandModalSearchParams: 'Sök efter ID, webbtext eller benämning',
   EmptySearchResult: 'Inga krav matchar det angivna sökkriteriet...',
   SearchForLevelOneDemands: 'Sök efter nivå 1 krav',
   SearchForLevelTwoDemands: 'Sök efter nivå 2 krav',
   ChangeOrder: 'Byt ordning',

   //Buttons
   FastMoveDemand: 'Snabbflytta krav',
   AbortFastMove: 'Avbryt snabbflytt',
   MoveTo: 'Flytta till',
   Move: 'Flytta',
   ExportDemandsAndQuestionsToExcel: 'Exportera till excel',
   ResetFilter: 'Återställ filtrering',
   Previous: 'Föregående',
   Next: 'Nästa',
   DemandsHaveBeenReviwed: 'Tagit del av alla krav',
   KeepRelevanceStatus: 'Behåll hanteringsstatus',

   // Dashboard
   Welcome: 'Välkommen',
   OngoingActivities: 'Pågående aktiviteter',
   Activity: 'Aktiviteter',
   StatusLaws: 'Status lagar',
   LatestUpdate: 'Senaste uppdatering',
   NumberOfCustomers: 'Antal kunder',
   NumberOfQuestions: 'Antal frågor',
   new: 'nya',
   revoked: 'upphävda',
   updated: 'uppdaterade',
   Changed: 'Förändrat',

   // Information texts
   NoAnswerOptions: 'Det finns inga svarsalternativ. För att skapa svarsalternativ, gå till',
   NoAnswerOptionsLink: 'Systeminställningar -> Svarsalternativ',

   // Demand compliance modules
   WORK_ENVIRONMENT: 'Arbetsmiljö',
   GDPR: 'GDPR',
   ISO27001: 'ISO 27 001',
   ISO28000: 'ISO 28 000',
   ISO31000: 'ISO 31 000',
   ISO45000: 'ISO 45 000',
   SSF_CYBER_SECURITY: 'SSF Cybersäkerhet',
   SYSTEMATIC_FIRE_PROTECTION_WORK: 'Systematiskt brandskyddsarbete',
   SECURITY_PROTECTION: 'Säkerhetsskydds',
   EXTENDED_LEVEL: 'Utökad nivå',

   // Subscriptions
   FREE: 'Gratis',
   MINI: 'Mini',
   BASE: 'Bas',
   BIG: 'Stor',
   PLUS: 'Plus',
   ADD_ON: 'Tillägg',
}
